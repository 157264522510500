.header {
	background: $color-pallete-1;

	&-logo {
		float: left;
		padding: 24px 0;

		@media screen and (max-width: $screen-sm) {
			float: none;
			padding: 16px 0;
			text-align: center;
		}
	}
}

.navbar {
	position: relative;

	&-menu {
		text-align: center;

		@media screen and (min-width: $screen-md-min) {
			display: block !important;
		}

		@media screen and (max-width: $screen-md) {
			position: absolute;
			display: none;
			top: 100%;
			left: 0;
			width: 100%;
			background: $color-pallete-1;
			z-index: 10;
		}

		&-item {
			display: inline-block;
			list-style-type: none;

			&.has-active {
				> a {
					color: $color-pallete-2;
				}
			}

			> a {
				display: block;
				padding: 12px 20px;
				text-transform: uppercase;
				color: $color-navbar-anchor;

				&:hover, &:focus {
					color: $color-pallete-2;
				}
			}

			@media screen and (min-width: $screen-md-min) {
				&.has-dropdown {
					position: relative;

					&:hover, &:focus {
						.navbar-menu-dropdown {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}

			@media screen and (max-width: $screen-md) {
				display: block;
				text-align: center;
			}
		}

		&-dropdown {
			position: absolute;
			top: 100%;
			top: calc(100% + 8px);
			left: 0;
			left: calc((100% - 175px) / 2);
			width: 175px;
			padding: 8px 0;
			z-index: 10;
			background: $color-light;
			box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.8);
			border-radius: 4px;
			opacity: 0;
			visibility: hidden;
			transition: visibility 0.2s ease, opacity 0.2s ease;

			@media screen and (max-width: $screen-md) {
				display: none;
			}

			&::before {
				position: absolute;
				top: -8px;
				left: 0;
				display: block;
				width: 100%;
				height: 8px;
				background: transparent;
				content: "";
			}

			&::after {
				position: absolute;
				bottom: 100%;
				left: 50%;
				height: 0;
				width: 0;
				border: solid transparent;
				pointer-events: none;
				border-color: rgba(255, 255, 255, 0);
				border-bottom-color: $color-light;
				border-width: 7px;
				margin-left: -7px;
				content: " ";
			}

			&-item {
				text-align: center;
				list-style-type: none;

				> a {
					display: block;
					text-transform: uppercase;
					color: $color-pallete-1;
					padding: 8px 4px;

					&:hover ,&:focus {
						color: $color-pallete-2;
					}
				}
			}
		}

		&-toggle {
			display: block;
			width: 100%;
			padding: 12px;
			color: $color-navbar-anchor;
			font-weight: 700;
			text-transform: uppercase;
			text-align: center;

			&:hover, &:focus {
				color: $color-pallete-2;
			}

			@media screen and (min-width: $screen-md-min) {
				display: none;
			}
		}
	}
}
