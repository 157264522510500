.title-default {
	color: $color-pallete-2;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 36px;

	&.is-small {
		font-size: 28px;
	}
}
