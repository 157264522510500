.feedback {
	&-box {
		&-content {
			padding: 120px 0;
			text-align: center;
		}

		&-title {
			margin-bottom: 16px;
			font-size: 64px;
			font-weight: 700;
			line-height: 64px;
			text-transform: uppercase;

			@media screen and (max-width: $screen-sm) {
				font-size: 44px;
				line-height: 44px;
			}
		}

		&-subtitle {
			margin-bottom: 20px;
			font-size: 28px;
			font-weight: 500;

			@media screen and (max-width: $screen-sm) {
				font-size: 24px;
			}
		}

		&-description {
			margin-bottom: 20px;
		}
	}
}
