.pagination {
	text-align: center;

	> li {
		display: inline-block;
		list-style-type: none;

		&.active{
			background: $color-pallete-2;
			> a {
				color: $color-light;
			}
		}

		&.disabled {
			> li {
				> a {
					pointer-events: none;
				}
			}
		}

		+ li {
			> a {
				border-left: none;
			}
		}

		> a {
			display: block;
			padding: 8px 16px;
			border: 1px solid $color-pallete-2;
			color: $color-pallete-3;
			pointer-events: none;

			&[href] {
				pointer-events: initial;
			}

			&:hover, &:focus {
				background: $color-pallete-2;
				color: $color-light;
			}
		}

		&:first-child {
			> a {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
		}

		&:last-child {
			> a {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
}
