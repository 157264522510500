.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	visibility: hidden;

	&.open {
		visibility: visible;

		.modal-overlay {
			opacity: 1;
		}

		.modal-box {
			transform: translate(-50%, -50%) scale(1);
		}
	}

	&.success {
		.modal-title {
			color: green;
		}
	}

	&.error {
		.modal-title {
			color: red;
		}
	}

	&-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.5s ease;
		opacity: 0;
		background: rgba(0, 0, 0, 0.7);
	}

	&-box {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(0);
		transform-origin: center center;
		max-width: 780px;
		width: 90%;
		max-height: 90%;
		padding: 30px 0;
		background: $color-light;
		border-radius: 4px;
		box-shadow: 0 0 10px -15px $color-dark;
		border: 1px solid #e6e6e6;
		color: $color-dark;
		transition: all 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550);
	}

	&-close {
		position: absolute;
		top: -14px;
		right: -5px;
		width: 28px;
		height: 28px;
		border-radius: 100%;
		background: lighten($color-dark, 20%);
		color: $color-light;
		border: 2px solid $color-light;

		&:hover, &:focus {
			background: $color-dark;
		}
	}

	&-header {
		position: relative;
		margin-bottom: 10px;
		padding: 0 10px;
		text-align: center;

		.modal-title {
			text-transform: uppercase;
			font-size: 28px;
			font-weight: 700;

			@media screen and (max-width: $screen-sm) {
				font-size: 22px;
			}
		}
	}

	&-content {
		padding: 10px 15px;
		font-size: 20px;
		text-align: center;
		overflow-y: auto;

		@media screen and (max-width: $screen-sm) {
			font-size: 16px;
		}

		@media screen and (max-width: $screen-sm) and (max-height: 380px) {
			height: 100px;
		}
	}
}
