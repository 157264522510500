* {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	font: inherit;
	vertical-align: baseline;
	line-height: 1.45;
}

html {
	font-size: 62.5%;
}

html,
body {
	width: 100%;
	height: 100%;
}

body {
	font-family: $font-family-primary;
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	text-rendering: optimizelegibility;
	color: $color-text;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block;
}

ul.row {
	> li {
		list-style-type: none;
	}
}

a {
	text-decoration: none;
	color: $color-anchor;

	&:hover, &:focus {
		color: darken($color-anchor, 10%);
	}
}

img {
	max-width: 100%;
	vertical-align: middle;
}

strong,
b {
	font-weight: 700;
}

em {
	font-style: italic;
}

button,
input[type="submit"] {
	font-family: $font-family-primary;
	font-size: 16px;
	background: none;
	cursor: pointer;
}

input,
textarea,
select {
	font-family: $font-family-primary;
	font-size: 14px;

	&.is-input-valid {
		border-color: $color-state-success;
	}

	&.is-input-invalid {
		border-color: $color-state-danger;
	}
}

input[type="search"] {
	appearance: none;
}

textarea {
	resize: vertical;
}

iframe {
	position: relative;
}

.header,
.main,
.footer {
	width: 100%;
	display: block;
	clear: both;
}
