.contact {
	&-title {
		font-size: 32px;
		text-transform: uppercase;
		color: $color-pallete-2;
		line-height: 36px;

		b {
			line-height: inherit;
		}

		@media screen and (max-width: $screen-lg) {
			font-size: 28px;
			line-height: 36px;
		}

		@media screen and (max-width: $screen-sm) {
			font-size: 24px;
			line-height: 28px;
		}
	}

	&-info {
		font-size: 24px;

		@media screen and (min-width: $screen-md-min) {
			&.has-margin {
				margin-bottom: 42px;
			}
		}

		@media screen and (max-width: $screen-sm) {
			font-size: 18px;
		}
	}

	&-map {
		overflow: hidden;

		&-iframe {
			position: relative;
			width: 100%;
			height: 400px;
			vertical-align: middle;

			@media screen and (max-width: $screen-sm) {
				height: 300px;
			}
		}
	}
}
