// Hero Carousel

.hero-carousel {
	height: 535px;
	background: $color-pallete-1;
	color: $color-light;

	@media screen and (max-width: $screen-lg) {
		height: 435px;
	}

	.owl-carousel,
	.owl-stage-outer,
	.owl-stage,
	.owl-item,
	.hero-carousel-item,
	.container {
		height: 100%;
	}

	.owl-nav {
		button {
			&.owl-prev, &.owl-next {
				position: absolute;
				font-size: 32px;
				line-height: 30px;
				color: $color-light;
				border: 1px solid $color-light;
				border-radius: 4px;

				&:hover, &:focus {
					background: $color-light;
					color: $color-pallete-1;
				}

				@media screen and (min-width: $screen-lg-min) {
					top: 50%;
					transform: translateY(-50%);
				}

				@media screen and (max-width: $screen-lg) {
					bottom: 20px;
					font-size: 24px;
					line-height: 20px;
				}
			}

			&.owl-prev {
				left: 15px;
				padding: 14px 16px 12px 14px !important;
			}

			&.owl-next {
				right: 15px;
				padding: 14px 14px 12px 16px !important;
			}
		}
	}

	.owl-dots {
		position: absolute;
		bottom: 30px;
		left: 0;
		width: 100%;
		text-align: center;

		.owl-dot {
			display: inline-block;
			width: 16px;
			height: 16px;
			margin: 0 6px;
			border-radius: 100%;
			background: $color-light;

			&.active {
				background: $color-pallete-1;
			}

			&:hover, &:focus {
				background: $color-pallete-1;
			}
		}
	}

	&-item {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		&-inner {
			position: relative;
			width: 100%;
			max-width: 400px;

			@media screen and (min-width: $screen-lg-min) {
				top: 50%;
				transform: translateY(-50%);
			}

			@media screen and (max-width: $screen-lg) {
				max-width: 600px;
				margin-top: 32px;
			}
		}
	}

	&-title {
		margin-bottom: 8px;
		font-size: 36px;
		font-weight: 700;
		line-height: 40px;
		text-transform: uppercase;

		@media screen and (max-width: $screen-sm) {
			font-size: 22px;
			line-height: 24px;
		}
	}

	&-description {
		margin-bottom: 24px;
	}
}

// Client Carousel

.client-carousel {
	padding: 0 30px;

	.owl-item {
		.client-carousel-item {
			position: relative;
			height: 140px;
			text-align: center;
		}

		.client-carousel-anchor {
			display: block;
			width: 100%;
			height: 100%;
			transition: transform 0.2s ease;

			&:hover, &:focus {
				transform: scale(0.9);
			}
		}

		.client-carousel-image {
			position: relative;
			display: block;
			top: 50%;
			transform: translateY(-50%);
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			margin: 0 auto;
		}
	}

	.owl-nav {
		button {
			&.owl-prev, &.owl-next {
				position: absolute;
				top: 50%;
				top: calc(50% - 28px);
				transform: translateY(-50%);
				font-size: 32px;
				color: $color-pallete-2;

				&:hover, &:focus {
					color: $color-pallete-1;
				}

				@media screen and (max-width: $screen-xs) {
					top: 50%;
				}
			}

			&.owl-prev {
				left: -30px;
			}

			&.owl-next {
				right: -30px;
			}
		}
	}

	.owl-dots {
		margin-top: 28px;
		text-align: center;

		.owl-dot {
			display: inline-block;
			width: 16px;
			height: 16px;
			margin: 0 6px;
			border-radius: 100%;
			background: $color-pallete-2;

			&.active {
				background: $color-pallete-1;
			}

			&:hover, &:focus {
				background: $color-pallete-1;
			}
		}
	}
}

// Product Gallery Carousel

.product-view-gallery-carousel {
	.owl-item {
		.product-view-gallery-carousel-item {
			height: 85px;

			a {
				display: block;
				width: 100%;
				height: 100%;
				padding: 4px;
				border: 1px solid $color-pallete-1;
				border-radius: 4px;
				transition: opacity 0.2s ease;

				&.is-active {
					border-color: $color-pallete-2;
				}

				&:hover, &:focus {
					opacity: 0.7;
				}

				img {
					position: relative;
					top: 50%;
					max-width: 100%;
					max-height: 100%;
					width: auto;
					margin: 0 auto;
					transform: translateY(-50%);
				}
			}
		}
	}

	.owl-dots {
		margin-top: 20px;
		text-align: center;

		.owl-dot {
			display: inline-block;
			width: 16px;
			height: 16px;
			margin: 0 6px;
			border-radius: 100%;
			background: $color-pallete-2;

			&.active {
				background: $color-pallete-1;
			}

			&:hover, &:focus {
				background: $color-pallete-1;
			}
		}
	}
}
