.btn {
	display: inline-block;
	padding: 4px 8px;
	border: 1px solid transparent;
	border-radius: 4px;
	font-size: 14px;
	text-align: center;

	&-uppercase {
		text-transform: uppercase;
	}

	&-block {
		display: block;
		width: 100%;
	}

	&-md {
		padding: 8px 16px;
	}

	&-lg {
		padding: 10px 24px;
		font-size: 18px;
	}

	&-default {
		color: $color-state-default;
		border: 1px solid $color-state-default;

		&:hover, &:focus {
			background: $color-state-default;
			color: $color-text;
		}
	}

	&-primary {
		background: $color-state-primary;
		color: $color-text;

		&:hover, &:focus {
			background: darken($color-state-primary, 5%);
			color: $color-text;
		}
	}

	&-info {
		background: $color-state-info;
		color: $color-light;

		&:hover, &:focus {
			background: darken($color-state-info, 10%);
			color: $color-light;
		}
	}
}
