// Colors default

$color-light:				#fff;
$color-dark:				#000;

$color-text:            	#222;

// Colors pallete

$color-pallete-1: 			#1e252b;
$color-pallete-2: 			#06a7e2;
$color-pallete-3:			#626262;

// Color Elements

$color-anchor:				$color-pallete-2;

$color-navbar-anchor:		#c2c6ca;

$color-footer:				#fcf8f8;

$color-input-background:	#ddf4fc;

// Bootstrap (States)

$color-state-default:		#ffffff;
$color-state-primary:		#06a7e2;
$color-state-info:			#1e252b;
$color-state-danger:		#e74c3c;
$color-state-success:		#2ecc71;

// Social Media

$color-social-behance: 		#1769ff;
$color-social-dropbox: 		#007ee5;
$color-social-facebook:		#3b5998;
$color-social-github:  		#333333;
$color-social-google:		#dd4b39;
$color-social-instagram:	#125688;
$color-social-lastfm: 		#d51007;
$color-social-linkedin: 	#007bb5;
$color-social-mailchimp:	#449a88;
$color-social-pinterest:	#cb2027;
$color-social-skype: 		#00acf4;
$color-social-snapchat: 	#fffc00;
$color-social-spotify: 		#2ebd59;
$color-social-telegram: 	#0088cc;
$color-social-tumblr: 		#32506d;
$color-social-twitter: 		#00aced;
$color-social-vimeo: 		#162221;
$color-social-whatsapp: 	#43d854;
$color-social-youtube: 		#bb0000;
