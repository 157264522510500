.form-control {
	display: block;
	width: 100%;
	height: 45px;
	padding: 12px 16px;
	background: $color-input-background;
	color: $color-text;
	font-style: italic;
	border: 1px solid transparent;
	border-radius: 4px;
	line-height: 45px;

	&:focus {
		background: darken($color-input-background, 5%);
	}

	@at-root textarea#{&} {
		height: auto;
		line-height: 20px;
    }
}

.form-search {
	position: relative;
	float: right;
	width: 300px;
	padding: 30px 0;

	&.is-filter {
		float: none;
		padding: 0;

		.form-search-input {
			color: $color-pallete-3;

			&::placeholder {
				color: $color-pallete-3;
				opacity: 0.8;
			}

			&:-ms-input-placeholder {
				color: $color-pallete-3;
				opacity: 0.8;
			}

			&::-ms-input-placeholder {
				color: $color-pallete-3;
				opacity: 0.8;
			}
		}

		.form-search-button {
			background: $color-pallete-2;
			color: $color-light;

			&:hover, &:focus {
				background: darken($color-pallete-2, 5%);
			}
		}
	}

	&-input {
		display: block;
		width: 100%;
		height: 44px;
		padding: 12px 44px 12px 12px;
		background: transparent;
		border: 1px solid $color-pallete-2;
		border-radius: 4px;
		font-style: italic;
		font-weight: 500;
		color: $color-light;

		&::placeholder {
			color: $color-light;
			opacity: 0.8;
		}

		&:-ms-input-placeholder {
			color: $color-light;
			opacity: 0.8;
		}

		&::-ms-input-placeholder {
			color: $color-light;
			opacity: 0.8;
		}
	}

	&-button {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		padding: 0 14px;
		color: $color-pallete-2;
		font-size: 18px;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;

		&:hover, &:focus {
			color: $color-light;
		}
	}

	@media screen and (max-width: $screen-sm) {
		width: 100%;
		padding: 16px 0;
		float: none;
	}
}

.form-organize {
	@media screen and (min-width: $screen-sm-min) {
		text-align: right;
	}

	&-label {
		margin-right: 8px;
		color: $color-pallete-1;
	}

	&-select {
		width: 220px;
		height: 44px;
		padding: 12px;
		line-height: 44px;
		font-style: italic;
		border-radius: 4px;
		border: 1px solid $color-pallete-2;
		color: $color-pallete-3;

		@media screen and (max-width: $screen-sm) {
			width: 75%;
			width: calc(100% - 112px);
		}
	}
}
