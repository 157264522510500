.footer {
	padding-top: 80px;
	background: $color-pallete-1;
	color: $color-footer;

	&-inner {
		&-info {
			font-size: 18px;

			+ .footer-inner-info {
				margin-top: 40px;
			}

			&.has-border {
				padding-bottom: 40px;
				border-bottom: 1px solid $color-pallete-2;
			}
		}
	}

	&-sitemap {
		&-item {
			list-style-type: none;

			+ .footer-sitemap-item {
				border-top: 1px solid $color-pallete-2;
			}

			&:first-child {
				> a {
					padding-top: 0;
				}
			}

			> a {
				display: block;
				padding: 12px 0;
				color: $color-footer;
				text-transform: uppercase;

				&:hover, &:focus {
					color: $color-pallete-2;
				}
			}
		}
	}

	&-copyright {
		padding: 10px 0;
		font-size: 14px;
	}

	&-credits {
		padding: 8px 0;
		text-align: right;

		&-item {
			display: inline-block;
			margin-left: 12px;
			list-style-type: none;

			> a {
				display: block;
				transition: transform 0.2s ease;

				&:hover, &:focus {
					transform: scale(0.8);
				}
			}
		}
	}
}
