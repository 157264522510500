.list-inside {
	&-item {
		list-style-type: none;

		&::before {
			margin-right: 8px;
			vertical-align: middle;
			color: $color-pallete-2;
			font-size: 20px;
			line-height: 20px;
			content: "•";
		}
	}
}

.list-check {
	&-item {
		position: relative;
		margin-bottom: 16px;
		padding-left: 40px;
		font-size: 22px;
		line-height: 26px;
		font-weight: 700;
		text-transform: uppercase;
		list-style-type: none;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: url('../img/icons/check.png');
		}

		@media screen and (max-width: $screen-sm) {
			font-size: 18px;
			line-height: 22px;
		}
	}
}

.list-locale {
	&-item {
		padding-bottom: 8px;
		list-style-type: none;

		> a {
			display: block;
			margin-bottom: 12px;
			font-weight: 700;
			font-size: 28px;
			color: $color-pallete-2;
			text-transform: uppercase;

			&::before {
				display: block;
				float: left;
				margin-right: 12px;
				color: $color-pallete-1;
				font-family: FontAwesome;
				transition: transform 0.15s ease;
				content: "\f105";
			}

			&:hover, &:focus {
				color: darken($color-pallete-2, 10%);
			}

			&.is-active {
				&::before {
					transform: rotate(90deg);
				}
			}
		}
	}

	&-dropdown {
		display: none;
		padding-left: 24px;

		&-item {
			margin-bottom: 8px;
			list-style-type: none;

			> a{
				color:$color-text;

				&:hover{
				    color: #0582b0;
				}
			}
		}
	}
}
