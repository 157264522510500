//== Grid sizes

// Number of columns in the grid.
$grid-columns:				12 !default;

// Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:			30px !default;

// --------------------------------------------------

//== Breakpoint sizes

// Extra small screen / phone
$screen-xs:					480px !default;
$screen-xs-min:				$screen-xs !default;

// Small screen / tablet
$screen-sm:					768px !default;
$screen-sm-min:				$screen-sm !default;

// Medium screen / desktop
$screen-md:					992px !default;
$screen-md-min:				$screen-md !default;

// Large screen / wide desktop
$screen-lg:					1200px !default;
$screen-lg-min:				$screen-lg !default;

// --------------------------------------------------

//== Container sizes

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) !default;
$container-lg:                 $container-large-desktop !default;