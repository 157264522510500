$margin-slug: 'margin-top', 'margin-right', 'margin-bottom', 'margin-left';
$margin-total: 20;
$margin-increment: 4;

@for $i from 1 through length($margin-slug) {
	@for $x from 0 through $margin-total {
		.#{nth($margin-slug, $i)}-#{$x} {
			#{nth($margin-slug, $i)}: ($x * $margin-increment) + px !important;
		}
	}
}
