.section-header {
	position: relative;
	height: 185px;
	background-color: $color-pallete-2;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	&-inner {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	&-title {
		font-size: 36px;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;
		color: $color-light;

		@media screen and (max-width: $screen-sm) {
			font-size: 24px;
		}
	}
}
