.client {
	&-map {
		position: relative;

		&.is-loading {
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url('../img/loading.gif') center center no-repeat;
				content: "";
			}
		}

		&-state {
			fill: #bcbdbf;

			&-active {
				fill: $color-pallete-2;
				cursor: pointer;

				&:hover, &:focus {
					fill: darken($color-pallete-2, 10%);
				}
			}
		}
	}
}
