$padding-slug: 'padding-top', 'padding-right', 'padding-bottom', 'padding-left';
$padding-total: 10;
$padding-increment: 4;

@for $i from 1 through length($padding-slug) {
	@for $x from 0 through $padding-total {
		.#{nth($padding-slug, $i)}-#{$x} {
			#{nth($padding-slug, $i)}: ($x * $padding-increment) + px !important;
		}
	}
}
