.navigation-inner {
	position: relative;
	margin-bottom: 20px;

	&-trigger {
		display: block;
		font-size: 28px;
		font-weight: 700;
		color: $color-pallete-2;
		text-transform: uppercase;
	}

	&-list {
		margin-top: 20px;

		&-item {
			margin-bottom: 12px;
			list-style-type: none;

			> a {
				color: $color-pallete-3;

				&::before {
					margin-right: 4px;
					font-weight: 700;
					color: $color-pallete-1;
					content: ">";
				}

				&:hover, &:focus {
					color: $color-pallete-2;
				}
			}
		}
	}
}
