.company {
	&-subtitle {
		margin-bottom: 40px;
		font-size: 28px;
		text-transform: uppercase;
		font-weight: 700;
		line-height: 36px;

		@media screen and (max-width: $screen-xs) {
			font-size: 24px;
			line-height: 30px;
		}
	}

	&-description {
		font-size: 20px;

		@media screen and (max-width: $screen-xs) {
			font-size: 18px;
		}
	}

	&-video-iframe {
		position: relative;
		width: 100%;
		height: 300px;
		vertical-align: middle;

		@media screen and (max-width: $screen-md) and (min-width: $screen-xs-min) {
			height: 400px;
		}
	}

	&-gallery {
		height: 280px;

		&-anchor {
			width: 100%;
			height: 100%;
			display: block;
			transition: opacity 0.15s ease;

			&:hover, &:focus {
				opacity: 0.7;
			}
		}

		&-image {
			width: 100%;
			height: 100%;
			overflow: hidden;
			border-radius: 4px;

			img {
				width: 100%;
				height: 100%;
				display: block;
				object-fit: cover;
			}
		}
	}

	&-services {
		font-size: 28px;
		text-align: center;
		line-height: 36px;

		p {
			margin-bottom: 40px;
		}

		@media screen and (max-width: $screen-xs) {
			font-size: 24px;
		}
	}
}
