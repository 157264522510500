.card-feature {
	position: relative;
	text-align: center;

	&.is-blue {
		.card-feature-anchor {
			color: $color-pallete-2;

			&:hover, &:focus {
				color: $color-pallete-2;
			}
		}
	}

	@media screen and (min-width: $screen-xs-min) {
		height: 100px;
	}

	&.has-border {
		&::before, &::after {
			position: absolute;
			display: block;
			background: #51c2eb;
			content: "";

			@media screen and (min-width: $screen-md-min) {
				top: 0;
				width: 2px;
				height: 100%;
			}

			@media screen and (max-width: $screen-md) {
				width: 100%;
				height: 2px;
				left: 0;
			}
		}

		&::before {
			@media screen and (min-width: $screen-md-min) {
				left: 0;
			}

			@media screen and (max-width: $screen-md) {
				top: -20px;
			}
		}

		&::after {
			@media screen and (min-width: $screen-md-min) {
				right: 0;
			}

			@media screen and (max-width: $screen-md) {
				bottom: -20px;
			}
		}
	}

	&-anchor {
		display: block;
		width: 100%;
		height: 100%;
		color: $color-light;
		transition: transform 0.2s ease;

		&:hover, &:focus {
			transform: scale(0.9);
			color: $color-light;
		}
	}

	&-inner {
		position: relative;
		display: inline-block;
		height: 100%;
		margin: 0 auto;
		padding-left: 125px;

		@media screen and (max-width: $screen-lg) {
			padding-left: 120px;
		}

		@media screen and (max-width: $screen-xs) {
			padding-left: 0;
		}
	}

	&-icon {
		@media screen and (min-width: $screen-xs-min) {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 105px;
		}

		@media screen and (max-width: $screen-xs) {
			margin-bottom: 16px;
			text-align: center;
		}
	}

	&-description {
		position: relative;
		display: block;
		font-size: 24px;
		line-height: 24px;

		b {
			display: block;
			text-transform: uppercase;
		}

		@media screen and (max-width: $screen-lg) {
			font-size: 16px;
			line-height: 20px;
		}

		@media screen and (min-width: $screen-xs-min) {
			top: 50%;
			transform: translateY(-50%);
			text-align: left;
		}
	}
}

.card-product {
	text-align: center;
	border: 1px solid $color-pallete-1;
	border-radius: 4px;

	&-image {
		position: relative;
		height: 300px;
		padding: 20px;

		img {
			position: relative;
			display: block;
			top: 50%;
			transform: translateY(-50%);
			max-width: 100;
			max-height: 100%;
			margin: 0 auto;
		}
	}

	&-inner {
		padding: 0 12px 20px 12px;
	}

	&-title {
		margin-bottom: 16px;
		font-size: 28px;
		color: $color-pallete-1;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 30px;

		@media screen and (min-width: $screen-sm-min) {
			height: 60px;
			overflow: hidden;
			margin-bottom: 8px;
		}
	}
}

.card-product-featured {
	text-align: center;
	border: 1px solid $color-pallete-1;
	border-radius: 4px;

	&-anchor {
		display: block;
		width: 100%;
		height: 100%;
		transition: opacity 0.2s ease;

		&:hover, &:focus {
			opacity: 0.7;
		}
	}

	&-image {
		position: relative;
		height: 300px;
		/*padding: 20px;*/

		img {
			position: relative;
			display: block;
			top: 50%;
			transform: translateY(-50%);
			max-width: 100;
			max-height: 100%;
			margin: 0 auto;
		}
	}

	&-inner {
		padding: 12px 16px;
		background: $color-pallete-1;
		color: $color-light;
	}

	&-title {
		font-size: 20px;
		font-weight: 700;
		line-height: 26px;
		text-transform: uppercase;

		@media screen and (min-width: $screen-sm-min) {
			display: block;
			display: -webkit-box;
			height: 52px;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}
	}
}
