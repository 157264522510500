.product {
	&-list {
		position: relative;

		&.is-loading {
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				min-height: 80px;
				display: block;
				background: rgba(255, 255, 255, 0.6) url('../img/loading.gif') no-repeat top center;
				content: "";
			}
		}

		&-feedback {
			padding: 20px;
			text-align: center;
			background: #e6e6e6;

			&.is-warning {
				background: lighten($color-pallete-2, 25%);
			}

			&.is-error {
				background: $color-state-danger;
			}
		}
	}

	&-view {
		&-image {
			height: 450px;
			margin-bottom: 20px;
			border: 1px solid $color-pallete-1;
			border-radius: 4px;
			text-align: center;

			&-anchor {
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				padding: 20px;

				&::after {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					transition: opacity 0.2s ease;
					background-color: rgba($color-pallete-2, 0.7);
					background-image: url("../img/icons/zoom.png");
					background-position: center center;
					background-repeat: no-repeat;
					content: "";
				}

				&:hover, &:focus {
					&::after {
						opacity: 1;
					}
				}
			}

			img {
				position: relative;
				display: block;
				top: 50%;
				transform: translateY(-50%);
				max-width: 100%;
				max-height: 100%;
				margin: 0 auto;
			}

			@media screen and (max-width: $screen-sm) {
				height: 380px;
			}

			@media screen and (max-width: $screen-xs) {
				height: 280px;
			}
		}

		&-title {
			margin-bottom: 28px;
			padding-bottom: 8px;
			font-size: 36px;
			font-weight: 700;
			text-transform: uppercase;
			border-bottom: 2px solid $color-pallete-2;

			@media screen and (max-width: $screen-sm) {
				font-size: 28px;
			}
		}

		&-description {
			h1, h2, h3, h4, h5, h6 {
				margin-bottom: 16px;
				font-size: 24px;
				text-transform: uppercase;
				font-weight: 700;

				@media screen and (max-width: $screen-sm) {
					font-size: 20px
				}
			}

			p {
				margin-bottom: 16px;
			}

			li {
				list-style-position: inside;
			}
		}
	}

	&-related {
		&-title {
			position: relative;
			margin-bottom: 52px;
			padding-bottom: 8px;
			font-size: 36px;
			font-weight: 700;
			text-transform: uppercase;

			&::after {
				position: absolute;
				display: block;
				bottom: 0;
				left: 0;
				width: 50%;
				height: 2px;
				background: $color-pallete-2;
				content: "";
			}

			@media screen and (max-width: $screen-sm) {
				font-size: 28px;
			}
		}
	}
}
