// Text State

.text-uppercase {
	text-transform: uppercase !important;
}

// Text Alignmenet

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

// Font Style

.font-italic {
	font-style: italic;
}
